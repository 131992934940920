.journey {
  height: 100svh !important;
  .journey-container {
    // background: rebeccapurple;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
 

    .section-title {
      // position: absolute;
      // top: 160px;
      // left: 0px;
      width: 100%;
      .special {
        position: relative;
        opacity: 0.6;
        display: flex;
        align-items: center;
        gap: 5px;
        .dash{
          height: 1px;
          width: 40px;
          background: var(--accent);
        }
      }
    
      .main {
        font-size: 40px;
      }
    }

    .journey-slider-box {
      width: 100%;
    //   height: 500px;
      
   

      .swiper {
        margin: 0px auto;
        width: 100%;
        height: 100%;
        padding-top: -100px;
      }

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}

.jour_slider {
  border-radius: 10px;
  background: var(--black-dark);
  padding: 20px;
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  gap: 20px;

 


  .jour_left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    height: 100%;
    justify-content: center;
  }

  .jour_right{
    width: 40%;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    position: relative;
    .bg-cover{
        height: 100%;
        width: 100%;
        position: absolute;
        top:0px;
        left: 0px;
        // background: linear-gradient( 90deg ,var(--black-dark) 0% , transparent 40%);
    }
  }
}


.r1{background: url("../assets/school.webp");}
.r2{background: url("../assets/fam2.webp");}
.r3{background: url("../assets/work.webp");}




@media only screen and (max-width: 1200px) {

  .journey {
    height: auto !important;
    padding: 50px 0px;}


  .jour_slider {
    flex-direction: column-reverse !important;
    gap: 20px;
    height: 900 !important;

    .jour_left {
      width: 100% !important;
      height: calc(100% - 300px) !important;
    }
    .jour_right{
      width: 100% !important;
      height: 300px;
      border-radius: 15px 15px 0px 0px;
      .bg-cover{
          height: 100%;
          width: 100%;
          position: absolute;
          top:0px;
          left: 0px;
          background: linear-gradient( 0deg ,var(--black-dark)  , transparent);
      }
    }
  }
  



}