.association {
  height: 100svh;
  background: var(--black);
//   background: red;


.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px; 
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}



.asso-slide{
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg ,black, rgba(0, 0, 0, 0.244));

    .asso-slide-bg{
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
    }
    
    .asso-slide-box{
        margin: auto;
        max-width: var(--max-width);
        height: 100%;
        display: flex;

        .asso-slide-box-left{
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;
            justify-content: center;
            gap: 20px;
        }
        .asso-slide-box-right{
            height: 100%;
            width: 50%;
        
           
        }









    }



}


.swiper-pagination-bullet {
    background: var(--white) !important;
}


.swiper-pagination-bullet-active{
    background: var(--accent) !important;
}



@media only screen and (max-width: 10000px) {

    .asso-slide{
        background: linear-gradient(0deg ,black, rgba(0, 0, 0, 0.244));
    }

    .asso-slide-box{
        flex-direction: column-reverse;
        padding: 20px !important;

        .asso-slide-box-left{
            height: 60% !important;
            width: 100% !important;
            align-items: center !important;
        }
        .asso-slide-box-right{
            height: 40% !important;
            width: 100% !important;
        }


    }








}
// pandoza------------------------------
@media only screen and (max-width: 1000px) {
    .asso1{
      background: url("../assets/pandoza_tab_newww.jpg")!important;
      z-index: -1 !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .asso1{
      background: url("../assets/snapedit_1707133830400.png")!important;
      z-index: -1 !important;
      background-size: contain !important;
      .asso-slide-box{
        margin: auto;
        max-width: var(--max-width);
        height: 100%;
        display: flex;

        .asso-slide-box-left{
            height: 100%;
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;
            justify-content: center;
            gap: 20px;
        }
        .asso-slide-box-right{
            height: 100% !important;
            width: 100% !important;
        
           
        }
      }
      
    }
  }

  //The indian journey ------------------------------


  @media only screen and (max-width: 1000px) {
    .asso2{
      background: url("../assets/the-in-tab-newww.jpg")!important;
      z-index: -1 !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .asso2{
      background: url("../assets/the-in-m-newww.jpg")!important;
      z-index: -1 !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
    
    }
  }

  // Akka foundation------------------------------
  

  @media only screen and (max-width: 1000px) {
    .asso3{
      background: url("../assets/tab-ak-n.jpg")!important;
      z-index: -1 !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .asso3{
      background: url("../assets/try.jpeg")!important;
      z-index: -1 !important;
      background-size: contain !important;
      
    }
  }


    // Diwise ------------------------------
  
  @media only screen and (max-width: 1000px) {
    .asso4{
      background: url("../assets/diwise-t-newwww.jpg")!important;
      z-index: -1 !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .asso4{
      
      background: url("../assets/jeevan.png")!important;
      z-index: -1 !important;
      background-size: contain !important;
      
      
    }
  }
  

