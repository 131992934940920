.Footer{
    height: 80px;
    background-color: #000001;
    border-top: 1px solid rgba(129, 127, 127, 0.527);

    .Footer-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        gap: 5px;
        
        
        
        .logo{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 100px;
            
            
            
           img{
            width: 30%;
           }
  
        }

        .menu{
            width: 100%;
            display: flex;
            flex-wrap:wrap;
            padding:0px 20px 0px 20px;
            align-items: center;
            justify-content: center;
            gap: 25px;
            
            
            
            

            a{
                color: white;
                text-decoration: none;
                font-size: 18px;
                opacity: 0.5;

                &:hover{
                    opacity: 1;
                }
            }


        }
        .copy{
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 20px 0px;
           
            height: 30px;
            
            h4{
                color: white;
            }
        }

        

       
        



    }
    
    
}

