.blogs_section{
    height: 100svh;
    background: var(--black-dark);
    .blogs_section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 30px;
        padding: 60px 0px;

        .section-title {
            // position: absolute;
            // top: 160px;
            // left: 0px;
            width: 100%;
            .special {
              position: relative;
              opacity: 0.6;
              display: flex;
              align-items: center;
              gap: 5px;
              .dash{
                height: 1px;
                width: 40px;
                background: var(--accent);
              }
            }
          
            .main {
              font-size: 40px;
            }
          }

        .blog-card-box{
            // height: 500px;
            width: 100%;
 
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;


            .swiper {
                width: 100%;
                height: 100%;
              }
             
              .swiper-slide {
                font-size: 18px;
                width:100% !important; //new css
                gap:20px;
                /* Center slide text vertically */
                display: flex;
                flex-wrap: wrap !important; //new css
                justify-content: center;
                align-items: center;
              }
        }   
    }
}



.blog-card, .blog-card1, .blog-card2{

    background: var(--black);
    width: 320px;
    height: 400px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:hover{
    
    }


    .img{
        background: url("../assets/blog1.jpg");
        width: 100%;
        height: 225px;
        position: relative;
        transition: var(--mt);

        .img-grad{
            position: absolute;
            height: 100%;
            width: 100%;
            transition: var(--mt);
            background: linear-gradient( 1800deg,var(--black) , transparent);
        }
        .cat{
          background: var(--accent);
          position: absolute;
          padding: 2px 10px;
          border-radius: 100px;
          right: 10px;
          top: 10px;
        }
    }

    .content{
        height: 175px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 20px;
        transition: var(--mt);
        position: relative;
        
    }


}


@media only screen and (max-width: 1000px) {

  .blogs_section{
    height: 230svh;
  }.blog-card-box{
    // height: 500px;
   


    .swiper {
        width: 100%;
        height: 100%;
      }
     
      .swiper-slide {

        flex-direction: column !important;
      }}


}