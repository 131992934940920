.hero {
  // background: radial-gradient( transparent , black);
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.192), black);
  // background: red;
  height: 100svh;

  .hero-container {
    .hero-left {
      // border: 1px solid red;
      padding-bottom: 20px;
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;

      h1 {
        font-size: 100px;
        line-height: 90px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {

    .hero{
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.192), black);
  .hero-container {
    .hero-left {
        width: 100% !important;
        justify-content: flex-end !important;
        padding-bottom: 50px !important;
    }
  }}
}
@media only screen and (max-width: 600px) {
  .hero-container {
    .hero-left {
        width: 100% !important;
        justify-content: flex-end !important;
        padding-bottom: 50px !important;
    }
  }
}
