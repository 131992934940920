.header {
  height: 60px;
  background: rgba(0, 0, 0, 0.33);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;

  &.scrolled {
    background: var(--black-dark);
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 100%;
      display: flex;
      align-items: center;

      img {
        height: 60%;
      }
    }

    .menu {
      height: 100%;
      aspect-ratio: 1 / 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 7px;
      padding: 10px;

      span {
        height: 4px;
        background: var(--white);
        display: block;
        border-radius: 20px;
        content: "";
        transition: var(--mt);

        &:nth-child(1) {
          width: 40px;
        }
        &:nth-child(2) {
          width: 30px;
        }
        &:nth-child(3) {
          width: 10px;
        }
      }
      &:hover {
        span {
          width: 40px;
        }
      }
    }
  }
}

.nav {
  height: 100svh;
  background: var(--black);
  position: absolute;
  top: 0px;
  z-index: 1001;
  right: 0%;
  width: 0%;
  transition: var(--mt);

  .close {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 50px;
    right: 50px;
    transition: var(--qt);
    z-index: 2000;

    &:hover {
      rotate: 15deg;
      scale: 1.1;
    }

    .line {
      height: 4px;
      background: var(--white);
      border-radius: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      width: 40px;
      transition: var(--mt);
      transform: translate(-50%, -50%) rotate(45deg);
      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.navopen {
    // left: 0%;
    width: 100%;
    

    .nav-container {
      overflow: hidden;

      .links a {
        animation: slide-in 1s forwards;
        --ad: 0.1s;

        @keyframes slide-in {
          from {
            transform: translateY(300px);
            opacity: 0;
          }
          to {
            transform: translateY(00px);
            opacity: 1;
          }
        }
        &:nth-child(2) {
          animation-delay: calc(var(--ad) * 2);
        }
        &:nth-child(3) {
          animation-delay: calc(var(--ad) * 3);
        }
        &:nth-child(4) {
          animation-delay: calc(var(--ad) * 4);
        }
        &:nth-child(5) {
          animation-delay: calc(var(--ad) * 5);
        }
        &:nth-child(6) {
          animation-delay: calc(var(--ad) * 6);
        }
        &:nth-child(7) {
          animation-delay: calc(var(--ad) * 7);
        }
        &:nth-child(8) {
          animation-delay: calc(var(--ad) * 8);
        }
      }

      .icons {
        .icon-box {
          animation: icon 1s forwards;

          @keyframes icon {
            0% {
              opacity: 0.1;
              transform: translateY(200px);
            }
            100% {
              opacity: 1;
              transform: translateY(0px);
            }
          }
        }
        .b2 {
          animation-delay: 0.2s;
        }
        .b3 {
          animation-delay: 0.4s;
        }
        .b4 {
          animation-delay: 0.6s;
        }
      }
    }
  }

  .nav-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .links {
      display: flex;
      flex-direction: column;
      gap: 40px;
      transition: var(--mt);

      // border: 1px solid red;
      width: 270px;

      &:hover {
        a {
          color: rgb(65, 65, 65);
        }
      }

      a {
        font-size: 30px;
        text-decoration: none;
        transition: var(--mt);
        font-weight: 600;
        transform: translateY(100px);
        opacity: 0;

        &:hover {
          margin-left: 30px;
          color: var(--white);
        }
      }
    }

    .icons {
      position: absolute;
      bottom: 100px;
      right: 0px;
      display: flex;
      gap: 30px;

      .icon-box {
        height: 60px;
        width: 60px;
        border: 1px solid white;
        border-radius: 100px;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--mt);
        opacity: 0;

        

        &:hover {
          transform: translateY(-20px);
          background: var(--white);

          svg {
            color: var(--black) !important;
            fill: var(--black) !important;
          }
        }
      }
    }

  }
}

@media only screen and (max-width: 600px) {
  .nav {
    .nav-container {
      .icons {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none !important;
      }
    }
  }
}
