.contact {
  height: 100svh;
  background: rgba(0, 0, 0, 0.93);

  .contact-container {
    display: flex;
    flex-direction: column;

    .contact-top {
      height: 25%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      // border: 1px solid red;
      .section-title {
        // position: absolute;
        // top: 160px;
        // left: 0px;
        width: 100%;
        .special {
          position: relative;
          opacity: 0.6;
          display: flex;
          align-items: center;
          gap: 5px;
          .dash {
            height: 1px;
            width: 40px;
            background: var(--accent);
          }
        }

        .main {
          font-size: 40px;
          //   padding-bottom: 20px;
        }
      }
    }

    .contact-bottom {
      height: 75%;
      width: 100%;
      display: flex;
      align-items: center;
      // border: 1px solid red;
      .contact-left {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .contact-box {
          background: var(--black-dark);
          height: 350px;
          width: 500px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 20px;
          position: relative;
          gap: 20px;

          h3 {
            span {
              color: var(--accent);
            }
          }

          .line {
            background: var(--text);
            opacity: 0.3;
            width: 90%;
            height: 1px;
            margin: 20px auto 40px auto;
          }
          .ib2{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          }

          .icon-box {
            padding: 10px;
            width: 100%;
            border: 1px solid var(--text);
            border-radius: 100px;
            display: flex;
            align-items: center;
            gap: 20px;
            transition: var(--mt);
            text-decoration: none;

           

            .icon {
              height: 40px;
              width: 40px;
              border-radius: 100px;
              background: var(--white);
              font-size: 24px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              
            &:hover {
              cursor: pointer;
              transform: scale(1.1);
            }

              svg {
                fill: var(--black-dark);
              }
            }
          }
        }
      }
      .contact-right {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {
          padding: 20px;
          width: 80%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 30px;

          input,
          textarea {
            padding: 10px 20px 10px 05px;
            font-size: 18px;
            background: none;
            border: none;
            outline: none;
            border-bottom: 1px solid var(--white);
            border-radius: 0px;
            width: 100%;
            resize: vertical;
            &::placeholder {
              opacity: 0.4;
            }

            &::focus {
              border-bottom: 1px solid var(--accent);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {

  .contact {
    height: auto;
    padding: 50px 0px;
  }

  .contact-box{
    height: fit-content !important;
  }

  .contact-container {
    flex-direction: column;
    .contact-top {
      height: 25% !important;
      width: 100%;
      display: flex;
      align-items: flex-end;
    }

    .contact-bottom {
      flex-direction: column-reverse !important;
      height: 75% !important;
      .contact-left {
        height: 20% !important;
        width: 100% !important;
        flex-direction: row-reverse !important;
        // display: none !important;

        .line ,h3{
            display: none;
        }
        

      }

      .contact-right{
        height: 80% !important;
        width: 100% !important;
      margin: 50px 0px;

        justify-content: flex-start !important;





      }



    }
  }
}
