:root {
  --black: #161616;
  --black-dark: rgb(15, 15, 15);
  --white: #f1f1f1;
  --accent: #cf000f;
  // --accent: rgb(255, 200, 0);
  --text: #b6b6b6;
  --qt: all 0.2s ease-out;
  --mt: all 0.5s ease-out;
  --lt: all 1s ease-out;
  --max-width: 1200px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0px;
  // border: 1px solid red;
  color: var(--white);
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");
// font-family: 'Roboto', sans-serif;
// font-family: 'Cedarville Cursive', cursive;

.special {
  font-family: "Cedarville Cursive", cursive;
  color: var(--white);
}

body {
  background: var(--black);
  width: 100% !important;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--white);
  font-weight: 700;
  text-wrap: balance !important;
  // max-width: 45ch;
  text-wrap: pretty !important;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 50px !important;
    line-height: 45px !important;
  }
  h2{
    font-size: 30px !important;

  }

  p,input,input::placeholder{
    font-size: 14px !important;
    line-height: 18px !important;
  }
}

p {
  color: var(--text);
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 1px;
  // word-spacing: 2px;
  line-height: 24px;
  text-align: justify;
  max-width: 75ch;
}

::selection {
  color: var(--white);
  background: var(--accent);
}

.btn {
  padding: 10px 20px;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--white);
  background: var(--accent);
  border: none;
  border: 2px solid var(--accent);
  cursor: pointer;
  font-size: 14px;
  transition: var(--st);

  &:hover {
    background: none;
    border: 2px solid var(--white);
    transition: var(--qt);
  }
}
.btn2 {
  padding: 10px 20px;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--white);
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: var(--st);
  background: none;
  border: 2px solid var(--white);


  &:hover {
    background: var(--accent);
    border: 2px solid var(--accent);
    transition: var(--qt);
  }
}

.parent {
  width: 100svw;
  position: relative;
  overflow: hidden;
  // position: sticky;

  // top: 0;

  .container {
    max-width: var(--max-width);
    height: 100%;
    margin: auto;
    position: relative;

    padding: 0px 10px;
  }

  .full-bg {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    animation: zoom-in ease-out 5s forwards;

     .full-bg1 {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    animation: zoom-in ease-out 5s forwards;}

    &.hero-bg {
      background: url("../src//assets//hero-desktop-newww.jpg");
    }
    &.contact-bg {
      filter: grayscale(1);
      background: url("https://img.freepik.com/free-photo/closeup-business-woman-hand-typing-laptop-keyboard-with-m_1232-2746.jpg?w=1060&t=st=1704656782~exp=1704657382~hmac=90ec5b8f365bb63bb0e91af92cf6f50cba5a2ba6d18ce51a83457b2c53826ec9");
    }
    @keyframes zoom-in {
      from {
        scale: 1.1;
      }
      to {
        scale: 1;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .hero-bg {
    background: url("./assets/tablet-hero.jpg") !important;
    z-index: -1 !important;
  }
}

@media only screen and (max-width: 600px) {
  .hero-bg {
    background: url("../src/assets/hero-mobile-pr.jpg") !important;
  }
}





/////////////////////////////////////////
///
///  scroll bar styles
///
/// /////////////////////////////////////

/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--accent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

////////////////////////
///

.bg-img-contain {
  background-position: center center !important ;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.bg-img-cover {
  background-position: center center !important ;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}



@media only screen and (max-width: 1000px) {
}

.top_btn{
  position: fixed;
  z-index: 20000;
  height: 50px;
  width: 50px;
  right: 20px;
  bottom: 0px;
  color: black;
  background: transparent;
  border: 3px solid white;
  font-size: 25px;
  font-weight: 200;
  
  cursor: pointer;
  opacity: 0;
  transition: var(--mt);
  pointer-events: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover{
    opacity: 1;
    background: var(--accent);
    border: none;
  }
  &.opacity{
    opacity: 0.5;
    pointer-events: all;
    bottom: 20px;

    &:hover{
      opacity: 1 !important;
    }
  }
  



}
