.pi {
  height: 100svh;
  background: var(--black-dark);

  .pi-container {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;

    .section-title {
      // position: absolute;
      // top: 160px;
      // left: 0px;
      width: 100%;
      .special {
        position: relative;
        opacity: 0.6;
        display: flex;
        align-items: center;
        gap: 5px;
        .dash{
          height: 1px;
          width: 40px;
          background: var(--accent);
        }
      }
    
      .main {
        font-size: 40px;
      }
    }

    .quote {
      display: flex;
      margin: 50px 0px;
      gap: 10px;

      h3 {
        font-size: 40px;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 700px) {

  .pi {
    height: auto !important;
    padding: 50px 0px !important;
  }

  .quote {
    display: flex;
    margin: 50px 0px;
    gap: 10px;

    h3 {
      font-size: 5vw !important;
      text-align: center;
    }
  }

  .pi_slider {

    flex-direction: column-reverse;
    align-items: center !important;
    height: 100%;
    width: 100%;
    gap: 20px;

  
    .pi_left {
      width: 100% !important;
      height: 100%;
    }
  
    .pi_right{
      width: 100% !important;
      height: 100%;
      max-width: 400px !important;
      border-radius: 10px 10px 10px 10px !important;
      .bg-cover{
          background: linear-gradient( 0deg ,var(--black-dark)  , transparent) !important;
      }
    }
  }


}



.pi-slider-box{
  width: 100%;
//   height: 500px;
  


  .swiper {
    margin: 0px auto;
    width: 100%;
    height: 100%;
    padding-top: -100px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
}

.pi_slider {
  border-radius: 10px;
  background: var(--black);
  padding: 20px;
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  gap: 20px;

  .pi_left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    height: 100%;
    justify-content: center;
  }

  .pi_right{
    width: 40%;
    aspect-ratio: 4/3;

    height: 100%;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    position: relative;
    .bg-cover{
        height: 100%;
        width: 100%;
        position: absolute;
        top:0px;
        left: 0px;
        // background: linear-gradient( 90deg ,var(--black)  , transparent 40%);
    }
  }
}

.father{background: url("../assets/father.webp")};
.mom{background: url("../assets/mom.webp");}

