.layout {
    min-height: 100vh;

    .layout-container {
        max-width: var(--max-width);
        margin: auto;
        min-height: 100vh;
        padding-top: 100px;


        .top {
            width: 100%;
            height: 450px;
            position: relative;
            border-radius: 20px;
            overflow: hidden !important;

            .imgbox {
                height: 100%;
                width: 100%;
                background: rgb(212, 96, 96);
                background-position: center center !important;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                position: absolute;
                top: 0px;
                left: 0px;


            }

            .overlay {
                height: 100%;
                width: 100%;
                background: linear-gradient(0deg, black, transparent);
                position: absolute;
                top: 0px;
                left: 0px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                flex-direction: column;
                padding: 30px;

                h1 {
                    color: var(--white);
                    font-size: 30px;
                }
            }
        }

        .mid {
            border-radius: 15px;
            padding: 20px 20px;
            width: 100%;
            margin: 10px auto;
            border: 1px solid rgb(231 231 231 / 17%);
            display: flex;
            align-items: center;
            gap: 10px;

            .date {
                background: red;
                padding: 10px 10px;
                color: white;
            }

            .category {
                background: red;
                padding: 10px 10px;
                color: white;
               
            }

        }

        .bottom {
            padding-bottom: 50px;


            h2 {}

            .data {
                padding: 10px;
                width: 100% !important;
                white-space: normal !important;
                color: white !important;

                p {
                    max-width: none !important;

                    span {
                        color: white !important;
                    }
                }


                h3 {}
            }

            .btn {
                margin-top: 30px;
                margin-left: 10px;
            }

        }
    }
}