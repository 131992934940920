.about{
    height: 100svh;
    background: var(--black);

    .about-container{
        display: flex;
        align-items: center;

        .about-left{
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .counter-box{
                border-top: 1px solid var(--text);
                border-left: 1px solid var(--text);
                border-bottom: 1px solid var(--text);
                padding: 50px;
                width: 60%;
                .count-top{
                    display: flex;
                    align-items: flex-end;
                    padding: 30px 0px;
                    h2{
                        font-size: 200px;
                    }
                    h3{
                        font-size: 150px;
                        color: var(--accent);
                        
                    }
                }

                .count-bottom{
                    padding: 10px 0px;
                   
                    h4{
                        text-align: right;
                        font-weight: 400;
                        font-size: 30px;
                    }
                }
            }

        }

        .about-right{
            height: 100%;
            width: 50%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 100px;

            .quote{
                display: flex;
                gap: 10px;

                h3{
                    font-size: 40px;
                    text-align: center;
                }
                
                
            }

            .quote-desc {
                display: flex;
                gap: 10px;

                .dash{
                    transform: scaleX(2);
                    padding-top: 3px;
                }


                
            }

            .qualities{

                display: flex;
                gap: 10px;

                .quality{

                    padding: 10px 20px;
                    border: 1px solid var(--text);
                    border-radius: 10px;
                    // background: var(--black-dark);
                }

            }



        }
       
    }

}


@media only screen and (max-width: 1000px) {

    .about{
        .about-container{
            flex-direction: column !important;
            .about-left{
                height: 55% !important;
                width: 100%  !important;
                .counter-box{
                    padding: 20px !important;
                    .count-top{
                        padding: 10px 0px !important;
                        h2{font-size: 120px !important; }
                        h3{font-size: 95px !important;}
                    }
                    .count-bottom{
                        padding: 0px 0px !important;
                        h4{font-size: 20px !important;}
                    }
                }
            }
    
            .about-right{
                height: 45%;
                width: 100%;
                padding-bottom: 50px;
                gap: 30px;
    
                .quote{
                    display: flex;
                    gap: 10px;
    
                    h3{
                        font-size: 30px !important;
                        text-align: center;
                        max-width: 45ch !important;
                    }
                    
                    
                }
    
                .quote-desc {
                    display: flex;
                    gap: 10px;
    
                    .dash{
                        transform: scaleX(2);
                        padding-top: 3px;
                        display: none;
                    }
                    p{
                        text-align: center;
                    }
    
    
                    
                }
    
                .qualities{
    
                    display: flex;
                    gap: 10px;
    
                    .quality{
    
                        padding: 10px 20px;
                        border: 1px solid var(--text);
                        border-radius: 10px;
                        // background: var(--black-dark);
                    }
    
                }
    
    
    
            }
           
        }
    
    }
    





}